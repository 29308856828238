@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.gallery {
  column-count: 3;
  padding: 0 12px;
}

@media (max-width: 1024px) {
  .gallery {
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
    column-count: 1;
  }
}
